import React from "react";
import "./groups.css";
import { connect } from "react-redux";
import {
  startAddGroups,
  startRefreshGroups,
} from "../../../redux/actions/group";
import {
  Table,
  Tooltip,
  Popconfirm,
  Button,
  Tag,
  Input,
  Modal,
  Popover,
} from "antd";
import moment from "moment";
import {
  fetchAllClubsAndLeagues,
  getGroupsFromLocal,
  getStats,
  searchGroupsFromLocal,
} from "../../../services/indexedDb/groups";
import {
  addNote,
  fbGetGroupsForClub,
} from "../../../services/firebaseService/endPoints/group";
import { FInput } from "../../commons/formFields/FInput";
import { FSelect } from "../../commons/formFields/FSelect";
import { switchCollapseHorizontal } from "../../../helperFunctions/util";
import { SearchOutlined } from "@ant-design/icons";
import { withRouter } from "../../../utils/helper";
import { TextSnippetBlack, NodeAddIcon } from "../../../utils/icons";
import "./groups.css";

const STATUS = [
  "Trial",
  "Onboarding",
  "Nurturing",
  "Active",
  "Risk",
  "Churned",
];
const ADOPTION = ["Stripe", "Website", "Directory", "Stadium", "Global"];
const OWNER = ["Abby", "Birdie", "Justin", "Tom"];

const DEFAULT_PAGE_SIZE = 100;

class ListGroup extends React.Component {
  constructor() {
    super();
    this.timeout = null;
    this.state = {
      isLoading: true,
      pagination: {
        defaultPageSize: DEFAULT_PAGE_SIZE,
        pageSize: DEFAULT_PAGE_SIZE,
      },
      tableData: [],
      sorter: { field: "", order: "" },
      search: "",
      stats: {
        regMembers: 0,
        preRegMembers: 0,
        totalMembers: 0,
      },

      filters: {
        search: "",
        club: "",
        league: "",
        status: "",
        adoption: "",
        owner: "",
      },
      allClubs: [],
      allLeagues: [],
      currentGroupId: "",
      currentGroupNotes: "",
      hasFilter: false,
      filterState: "stable",
    };
  }

  handleEditGroup = (data) => {
    localStorage.setItem(
      "groupsConfiguration",
      JSON.stringify({
        pagination: this.state.pagination,
        tableData: this.state.tableData,
        sorter: this.state.sorter,
      })
    );

    this.props.router.navigate(`/superadmin/groups/edit/${data.id}`);
  };

  handleSearch = (selectedKeys, confirm, field) => {
    if (confirm) confirm();
    this.setState({
      search: selectedKeys[0],
      isLoading: !!selectedKeys[0],
      tableData: [],
    });
    if (selectedKeys[0]) {
      searchGroupsFromLocal(selectedKeys[0], field).then((data) => {
        let [regMembers, preRegMembers, totalMembers] = [0, 0, 0];
        data.forEach((rec) => {
          regMembers += rec.registeredMembers;
          preRegMembers += rec.preRegisteredMemberCount;
          totalMembers += rec.totalMembers;
          if (rec.isParent) {
            delete rec.children;
          }
        });
        this.setState({
          pagination: false,
          tableData: data,
          isLoading: false,
          sorter: { field: "", order: "" },
          stats: { regMembers, preRegMembers, totalMembers },
        });
      });
    } else {
      // this.handleReset()
    }
  };

  handleReset = (clearFilters) => {
    if (clearFilters) clearFilters();
    this.setState({ search: "", tableData: [], pagination: {} });
  };

  handleGroupsRefresh = () => {
    this.props.dispatch(startRefreshGroups());
    this.props.dispatch(startAddGroups());
    this.setState({ isLoading: true, tableData: [], pagination: {} });
  };

  handleTableChange = (pagination, filters, sorter) => {
    if (!this.state.search) {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({
        pagination: pager,
        sorter,
      });
      this.fetchGroups((pager.current - 1) * DEFAULT_PAGE_SIZE, sorter);
      document.querySelector(".ant-table-body").scrollTo(0, 0);
    }
  };

  fetchGroups = (skip, sorter = this.state.sorter) => {
    let orderBy = sorter.field;
    let order = sorter.order;
    this.setState({ isLoading: true });

    let promise1 = getGroupsFromLocal(
      skip || 0,
      DEFAULT_PAGE_SIZE,
      orderBy,
      order === "ascend" ? "next" : "prev",
      this.state.filters
    ).then((data) => {
      return new Promise(async (resolve, reject) => {
        let promises = [];
        data.forEach(async (group) => {
          if (group.children) {
            if (group.children.includes("All")) {
              promises.push(
                new Promise(async (res, rej) => {
                  group.children = await fbGetGroupsForClub(group.clubId).then(
                    (groups) => {
                      return groups.filter((doc) => doc.id !== group.id);
                    }
                  );
                  res();
                })
              );
            } else if (group.children.includes("Official Groups")) {
              promises.push(
                new Promise(async (res, rej) => {
                  group.children = await fbGetGroupsForClub(group.clubId).then(
                    (groups) => {
                      let res = [];
                      groups.forEach((childGrp) => {
                        if (
                          childGrp.groupType === "Official Supporters Group" &&
                          childGrp.id !== group.id
                        ) {
                          res.push(childGrp);
                        }
                      });

                      return res;
                    }
                  );
                  res();
                })
              );
            } else {
              promises.push(
                new Promise(async (res, rej) => {
                  let children = [...group.children];
                  group.children = await fbGetGroupsForClub(group.clubId).then(
                    (groups) => {
                      return groups.filter((doc) => children.includes(doc.id));
                    }
                  );
                  res();
                })
              );
            }
          }
        });
        await Promise.all(promises);
        let result = {
          tableData: data,
          isLoading: false,
        };

        resolve(result);
      });
    });

    let promise2 = getStats(this.state.filters);

    Promise.all([promise1, promise2]).then((res) => {
      this.setState({
        ...res[0],
        stats: {
          ...res[1],
        },
      });
    });
  };

  fetchAllClubsAndLeaguesData = () => {
    fetchAllClubsAndLeagues().then(({ allClubs, allLeagues }) => {
      this.setState({
        allClubs,
        allLeagues,
      });
    });
  };

  getFilterDropdown = (field) => {
    return ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search group`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, field)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, field)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    );
  };

  getFilteredIcon = (filtered) => (
    <SearchOutlined
      style={{ color: true ? "#1890ff" : undefined, fontSize: "16px" }}
    />
  );

  onFilterDropdownVisibleChange = (visible) => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  };

  copyEmail = (e) => {
    let el = e.target.children[0];
    let dummy = document.createElement("textarea");
    dummy.value = el.value;
    document.body.appendChild(dummy);
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  };

  onFilterChange = () => {
    let filters = this.state.filters;
    let hasFilter =
      Object.keys(filters).filter((key) => `${filters[key]}`.length > 0)
        .length > 0;
    this.setState(
      {
        tableData: [],
        hasFilter: hasFilter,
        pagination: hasFilter
          ? false
          : { defaultPageSize: DEFAULT_PAGE_SIZE, pageSize: DEFAULT_PAGE_SIZE },
      },
      () => {
        if (this.state.filterState === "unstable") {
          if (this.timeout) {
            clearTimeout(this.timeout);
          }
          this.timeout = setTimeout(() => {
            this.setState({
              filterState: "stable",
            });
          }, 500);
        } else {
          this.fetchGroups();
        }
      }
    );
  };

  handleEditNotes = (data) => {
    this.setState({
      currentGroupId: data.id,
      currentGroupNotes: data.notes,
    });
  };

  addNotes = () => {
    addNote(this.state.currentGroupNotes, this.state.currentGroupId).then(
      () => {
        this.setState(
          () => ({
            currentGroupId: "",
            currentGroupNotes: "",
          }),
          () => {
            this.props.dispatch(startRefreshGroups());
            this.props.dispatch(startAddGroups());
          }
        );
      }
    );
  };

  render() {
    const columns = [
      {
        title: window.screen.width > 500 ? "Action" : "",
        width: window.screen.width > 500 ? 80 : 30,
        render: (data) => (
          <div className="d-flex">
            <Tooltip title="edit">
              <i
                className="fa fa-pencil cursor-pointer"
                aria-hidden="true"
                onClick={() => {
                  this.handleEditGroup(data);
                }}
              />
            </Tooltip>
            {data.notes ? (
              <Tooltip
                title={<div style={{ maxWidth: 400 }}>{data.notes}</div>}
              >
                <i
                  className="ml-3"
                  aria-hidden="true"
                  onClick={() => {
                    this.handleEditNotes(data);
                  }}
                >
                  <img
                    src={TextSnippetBlack}
                    style={{ width: 25, height: 25 }}
                  />
                </i>
              </Tooltip>
            ) : (
              <i
                className="ml-3"
                aria-hidden="true"
                onClick={() => {
                  this.handleEditNotes(data);
                }}
              >
                <img src={NodeAddIcon} style={{ width: 25, height: 25 }} />
              </i>
            )}
          </div>
        ),
        // fixed: 'left'
      },
      {
        title: "Group",
        key: "groupName",
        dataIndex: "groupName",
        sorter: true,
        sortOrder:
          this.state.sorter.field === "groupName" && this.state.sorter.order,
        // fixed: 'left',
        render: (groupName) => {
          return <span style={{ fontWeight: "bold" }}>{groupName}</span>;
        },
        filterDropdown: this.getFilterDropdown("groupName"),
        filterIcon: this.getFilteredIcon,
        onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
      },
      {
        title: "ID",
        key: "groupId",
        dataIndex: "id",
        render: (data) => {
          return (
            <div>
              <Popover placement="topLeft" content="Copy">
                <div
                  id={data}
                  onClick={this.copyEmail}
                  style={{
                    cursor: "pointer",
                    position: "relative",
                  }}
                >
                  {data}
                  <textarea id="groupId" style={{ display: "none" }}>
                    {data}
                  </textarea>
                </div>
              </Popover>
            </div>
          );
        },
      },
      {
        title: "Owner",
        key: "owner",
        dataIndex: "owner",
        render: (data) => <div style={{ minWidth: 100 }}>{data}</div>,
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
      },
      {
        title: "% Reg",
        width: window.screen.width > 500 ? 100 : 50,
        key: "percentageRegistered",
        render: (data) => {
          let rm = data.registeredMembers || 0;
          let tm = data.totalMembers || 0;
          let val = (rm / tm) * 100;
          return (Number.isNaN(val) ? 0 : Math.round(val)) + "%";
        },
        dataIndex: "",
      },
      {
        title: `Reg\n(${this.state.stats.regMembers})`,
        width: window.screen.width > 500 ? 105 : 80,
        key: "registeredMembers",
        render: (registeredMembers) => registeredMembers || 0,
        dataIndex: "registeredMembers",
        sorter: true,
        sortOrder:
          this.state.sorter.field === "registeredMembers" &&
          this.state.sorter.order,
      },
      {
        title: `Pending\n(${this.state.stats.preRegMembers})`,
        width: 120,
        key: "preRegisteredMemberCount",
        dataIndex: "preRegisteredMemberCount",
        render: (preRegisteredMemberCount) => preRegisteredMemberCount || 0,
        sorter: true,
        sortOrder:
          this.state.sorter.field === "preRegisteredMemberCount" &&
          this.state.sorter.order,
      },
      {
        title: `Total\n(${this.state.stats.totalMembers})`,
        width: 105,
        key: "totalMembers",
        dataIndex: "totalMembers",
        render: (total) => total || 0,
        sorter: true,
        sortOrder:
          this.state.sorter.field === "totalMembers" && this.state.sorter.order,
      },
      {
        title: "Stripe?",
        key: "hasStripe",
        render: (record) => (
          <span style={{ fontWeight: "bold" }}>
            {record.paymentDetails && record.paymentDetails.stripeAccountId
              ? "Yes"
              : "No"}
          </span>
        ),
      },
      {
        title: "Website",
        dataIndex: "websitePublished",
        key: "website",
        render: (data) => <span>{data ? "Yes" : "No"}</span>,
      },
      {
        title: "Directory",
        dataIndex: "directoryPublished",
        key: "directory",
        render: (data) => <span>{data ? "Yes" : "No"}</span>,
      },
      {
        title: "Stadium",
        dataIndex: "stadiumPublished",
        key: "stadium",
        render: (data) => <span>{data ? "Yes" : "No"}</span>,
      },
      {
        title: "Global post",
        key: "clubPostAllowed",
        dataIndex: "clubPostAllowed",
        render: (value) => (
          <span style={{ fontWeight: "bold" }}>{value ? "Yes" : "No"}</span>
        ),
      },
      {
        title: "Club",
        key: "clubName",
        dataIndex: "clubName",
        sorter: true,
        sortOrder:
          this.state.sorter.field === "clubName" && this.state.sorter.order,
        filterDropdown: this.getFilterDropdown("clubName"),
        filterIcon: this.getFilteredIcon,
        onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
      },
      {
        title: "League",
        key: "leagueName",
        dataIndex: "leagueName",
        sorter: true,
        sortOrder:
          this.state.sorter.field === "leagueName" && this.state.sorter.order,
        filterDropdown: this.getFilterDropdown("leagueName"),
        filterIcon: this.getFilteredIcon,
        onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
      },

      {
        title: "Renewal Date",
        key: "renewalDate",
        dataIndex: "renewalDate",
        sorter: true,
        sortOrder:
          this.state.sorter.field === "renewalDate" && this.state.sorter.order,
        render: (renewalDate) => {
          return new moment.unix(renewalDate).format("YY-MM-DD");
        },
      },
      {
        title: "Admins",
        key: "admins",
        dataIndex: "admins",
        render: (admins, record) => {
          return (
            <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
              {admins &&
                [...admins[0], ...admins[1]].map((admin) => {
                  return (
                    <li>
                      <Popover placement="topLeft" content="Copy Email">
                        <div
                          id={record.id}
                          onClick={this.copyEmail}
                          style={{
                            cursor: "pointer",
                            position: "relative",
                            color: "#486ddb",
                          }}
                        >
                          {admin.name}
                          <textarea id="email" style={{ display: "none" }}>
                            {admin.email}
                          </textarea>
                        </div>
                      </Popover>
                    </li>
                  );
                })}
            </ul>
          );
        },
      },

      {
        title: "Group Type",
        key: "groupType",
        dataIndex: "groupType",
        render: (type) => <span style={{ fontWeight: "bold" }}>{type}</span>,
        sorter: true,
        sortOrder:
          this.state.sorter.field === "groupType" && this.state.sorter.order,
      },
      {
        title: "Highest Membership Number (UserGroup)",
        key: "lastMembershipNumberUserGroup",
        dataIndex: "lastMembershipNumberUserGroup",
        render: (data) => <div style={{ minWidth: 100 }}>{data}</div>,
      },
      {
        title: "Highest Membership Number (Invite_Emails)",
        key: "lastMembershipNumberInviteEmails",
        dataIndex: "lastMembershipNumberInviteEmails",
        render: (data) => <div style={{ minWidth: 100 }}>{data}</div>,
      },
      {
        title: "Last Membership Count",
        key: "lastMembershipCount",
        dataIndex: "lastMembershipCount",
        render: (data) => <div style={{ minWidth: 100 }}>{data}</div>,
      },
    ];

    const innerColumns = [
      {
        title: "Group Name",
        dataIndex: "groupName",
        key: "groupName",
        render: (name) => name,
      },
    ];

    let dataCopy = JSON.parse(JSON.stringify(this.state.tableData));

    return (
      <div className="superadmin-groups-container">
        {this.state.currentGroupId && (
          <Modal
            title={<h5>Edit notes</h5>}
            width={700}
            centered
            open={this.state.currentGroupId}
            onOk={() => {
              this.addNotes();
            }}
            onCancel={() => {
              this.setState({
                currentGroupId: "",
                currentGroupNotes: "",
              });
            }}
          >
            <div className="my-row">
              <textarea
                maxLength={1000}
                rows={10}
                style={{
                  borderRadius: 5,
                  width: "100%",
                  borderColor: "#ced4da",
                }}
                value={this.state.currentGroupNotes}
                name="newsIntro"
                onChange={(e) => {
                  this.setState({
                    currentGroupNotes: e.target.value,
                  });
                }}
              />
            </div>
          </Modal>
        )}

        <div className="mt-3">
          <div className="col filters-bottom-0">
            <div id="super-group-filter">
              {/* <a
                class="icon"
                onClick={() => switchCollapseHorizontal("super-group-filter")}
              >
                Filters
              </a> */}
              <div className="create-group-filters">
                <div className="flex-center">
                  <FInput
                    value={this.state.filters.search}
                    onChange={(e) => {
                      let filters = this.state.filters;
                      filters.search = e.target.value;
                      this.setState(
                        {
                          filters,
                          filterState: "unstable",
                        },
                        () => {
                          this.onFilterChange();
                        }
                      );
                    }}
                    name="search"
                    display="my-row"
                    placeholder="Search by group"
                    tabIndex="2"
                    style={{ width: "200px" }}
                  />
                </div>
                <div className="flex-center" style={{ padding: "0px 15px" }}>
                  <FSelect
                    value={this.state.filters.club}
                    onChange={(e) => {
                      let filters = this.state.filters;
                      filters.club = e.target.value;
                      this.setState(
                        {
                          filters,
                        },
                        () => {
                          this.onFilterChange();
                        }
                      );
                    }}
                    dataList={this.state.allClubs}
                    dataKey="id"
                    dataValue="label"
                    style={{
                      backgroundColor: "transparent",
                      border: "0px none transparent",
                      borderBottom: "2px solid white",
                      color: "white",
                      borderRadius: 0,
                      fontSize: 16,
                      width: "200px",
                    }}
                    name="status"
                    display="my-row"
                    placeholder="Club"
                    tabIndex="2"
                  />
                </div>
                <div className="flex-center" style={{ padding: "0px 15px" }}>
                  <FSelect
                    value={this.state.filters.league}
                    onChange={(e) => {
                      let filters = this.state.filters;
                      filters.league = e.target.value;
                      this.setState(
                        {
                          filters,
                        },
                        () => {
                          this.onFilterChange();
                        }
                      );
                    }}
                    dataList={this.state.allLeagues}
                    style={{
                      backgroundColor: "transparent",
                      border: "0px none transparent",
                      borderBottom: "2px solid white",
                      color: "white",
                      borderRadius: 0,
                      fontSize: 16,
                      width: "200px",
                    }}
                    dataKey="id"
                    dataValue="label"
                    name="League"
                    display="my-row"
                    placeholder="League"
                    tabIndex="2"
                  />
                </div>
                <div className="flex-center" style={{ padding: "0px 15px" }}>
                  <FSelect
                    value={this.state.filters.status}
                    onChange={(e) => {
                      let filters = this.state.filters;
                      filters.status = e.target.value;
                      this.setState(
                        {
                          filters,
                        },
                        () => {
                          this.onFilterChange();
                        }
                      );
                    }}
                    dataList={STATUS}
                    dataOnlyValue={true}
                    style={{
                      backgroundColor: "transparent",
                      border: "0px none transparent",
                      borderBottom: "2px solid white",
                      color: "white",
                      borderRadius: 0,
                      fontSize: 16,
                      width: "200px",
                    }}
                    name="status"
                    display="my-row"
                    placeholder="Status"
                    tabIndex="2"
                  />
                </div>
                <div className="flex-center" style={{ padding: "0px 15px" }}>
                  <FSelect
                    value={this.state.filters.adoption}
                    onChange={(e) => {
                      let filters = this.state.filters;
                      filters.adoption = e.target.value;
                      this.setState(
                        {
                          filters,
                        },
                        () => {
                          this.onFilterChange();
                        }
                      );
                    }}
                    dataList={ADOPTION}
                    dataOnlyValue={true}
                    style={{
                      backgroundColor: "transparent",
                      border: "0px none transparent",
                      borderBottom: "2px solid white",
                      color: "white",
                      borderRadius: 0,
                      fontSize: 16,
                      width: "200px",
                    }}
                    name="status"
                    display="my-row"
                    placeholder="Adoption"
                    tabIndex="2"
                  />
                </div>
                <div className="flex-center" style={{ padding: "0px 15px" }}>
                  <FSelect
                    value={this.state.filters.owner}
                    onChange={(e) => {
                      let filters = this.state.filters;
                      filters.owner = e.target.value;
                      this.setState(
                        {
                          filters,
                        },
                        () => {
                          this.onFilterChange();
                        }
                      );
                    }}
                    dataList={OWNER}
                    dataOnlyValue={true}
                    style={{
                      backgroundColor: "transparent",
                      border: "0px none transparent",
                      borderBottom: "2px solid white",
                      color: "white",
                      borderRadius: 0,
                      fontSize: 16,
                      width: "200px",
                    }}
                    name="status"
                    display="my-row"
                    placeholder="Owner"
                    tabIndex="2"
                  />
                </div>
                <div>
                  <ul
                    style={{ fontSize: "15px", listStyle: "none", margin: "0" }}
                  >
                    <li className="ml-auto align-items-center">
                      <div className="d-flex justify-content-end">
                        {!this.state.search && (
                          <>
                            <Popconfirm
                              title={"Refresh groups?"}
                              onConfirm={() => {
                                this.handleGroupsRefresh();
                              }}
                              onCancel={() => {}}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button type={"primary"} size="small">
                                {"Refresh"}
                              </Button>
                            </Popconfirm>
                          </>
                        )}
                        &nbsp; &nbsp;
                        <Tag color={this.state.search && "magenta"}>
                          {this.state.hasFilter &&
                            `${this.state.tableData.length} match found for applied filters `}
                          {!this.state.hasFilter &&
                            `${this.state.pagination.total} groups`}
                        </Tag>
                      </div>
                      <div className="d-flex justify-content-end mx-2">
                        <i>
                          Last refreshed today at:{" "}
                          {this.props.adminData.groups.date &&
                            moment
                              .unix(this.props.adminData.groups.date)
                              .format("HH:mm")}
                        </i>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Table
          className="main-groups-table"
          bordered
          columns={
            window.screen.width > 1023
              ? columns
              : window.screen.width > 400
                ? columns.slice(0, 5)
                : columns.slice(0, 4)
          }
          bodyStyle={{
            fontSize: window.screen.width > 500 ? "18px" : "12px",
            // color: "var(--primary-text-color)",
          }}
          dataSource={[...dataCopy]}
          pagination={{
            position: ["bottomLeft"],
            defaultPageSize: 50,
            showSizeChanger: true,
            pageSizeOptions: [10, 20, 50, 100],
          }}
          loading={this.state.isLoading}
          onChange={this.handleTableChange}
          rowKey="id"
          // size={"small"}
          overflow="hidden"
          scroll={window.screen.width > 500 ? { x: "max-content" } : { x: 460 }}
        />
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !this.state.hasFilter &&
      this.props.adminData.groups.records !== this.state.pagination.total &&
      this.props.adminData.groups.records !== undefined
    ) {
      this.setState(
        (prevState) => {
          let pagination = { ...prevState.pagination };
          pagination.total = this.props.adminData.groups.records;
          pagination.defaultPageSize = DEFAULT_PAGE_SIZE;
          pagination.pageSize = DEFAULT_PAGE_SIZE;
          return { pagination, tableData: [] };
        },
        () => {
          this.fetchGroups(this.state.pagination.current || undefined);
          this.fetchAllClubsAndLeaguesData();
        }
      );
    }

    if (this.state.filterState !== prevState.filterState) {
      if (this.state.hasFilter && this.state.filterState === "stable") {
        this.fetchGroups();
      }
    }
  }

  componentDidMount() {
    let config = localStorage.getItem("groupsConfiguration");
    if (config) {
      config = JSON.parse(config);
      this.fetchGroups(
        (config.pagination.current - 1) * DEFAULT_PAGE_SIZE,
        config.sorter,
        config.filters
      );
      this.fetchAllClubsAndLeaguesData();
      this.setState({ ...config, isLoading: false });
    } else {
      this.props.dispatch(startAddGroups());
    }
    localStorage.setItem("groupsConfiguration", "");
  }
}
const mapStateToProps = (state) => {
  return {
    adminData: { groups: { ...state.groups } },
  };
};

export default connect(mapStateToProps)(withRouter(ListGroup));
