import { Popconfirm, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DeleteIcon, EditIcon } from "../../../utils/icons";
import { sortObjectByString } from "../../../utils/helper";
import dayjs from "dayjs";
import {
  fbDeleteLocation,
  getLocations,
} from "../../../services/firebaseService/endPoints/admin/content";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentGroupData } from "../../../redux/selectors/adminData";
import { setGettingStartedItemStatus } from "../../../redux/actions/adminData";
import {
  GUIDE_SETUP,
  TOPIC_ADD_LOCATIONS,
} from "../../../constants/gettingStarted";
import ScreenHeader from "../../../CommonComponents/ScreenHeader";
import ChantButton from "../../../CommonComponents/ChantButton/ChantButton";
import DescriptionText from "../../../CommonComponents/DescriptionText";
import "./Locations.css";
import logger from "../../../utils/logger";

const Locations = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const groupData = useSelector(selectCurrentGroupData);

  const [tableData, setTableData] = useState([]);
  const [lastSeason, setLastSeason] = useState("");
  const [loading, setLoading] = useState(false);

  const handleEditLocation = (data) => {
    navigate(`/admin/content/locations/edit/${data.id}`);
  };

  const handleDeleteLocation = (id) => {
    fbDeleteLocation(groupData.id, id).then(() => {
      fetchLocations();
    });
  };

  const fetchLocations = async () => {
    try {
      setLoading(true);

      const locations = await getLocations(groupData.id);
      let lastSeason = "";

      if (locations?.length > 0) {
        dispatch(
          setGettingStartedItemStatus(
            groupData.id,
            GUIDE_SETUP,
            TOPIC_ADD_LOCATIONS,
            true
          )
        );
      }

      locations?.forEach((data) => {
        let seasonsForDoc = Object.keys(data.totalCheckIn || {});
        seasonsForDoc.sort();
        let lastSeasonForDoc = seasonsForDoc.pop();
        if (
          !lastSeason ||
          (lastSeason !== dayjs().year() && lastSeason < lastSeasonForDoc)
        ) {
          lastSeason = lastSeasonForDoc;
        }
      });

      locations.sort((a, b) => a.name.localeCompare(b.name));

      setTableData(locations);
      setLastSeason(lastSeason);
    } catch (error) {
      logger.error("Error fetching locations:", error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Action",
      width: 120,
      className: "header-white",
      render: (data) => {
        return (
          <div className="flex-center">
            <Tooltip title="edit">
              <i className="ml-1" onClick={() => handleEditLocation(data)}>
                <img
                  alt="edit"
                  src={EditIcon}
                  style={{ width: 25, height: 25 }}
                />
              </i>
            </Tooltip>
            <Tooltip title="delete">
              <Popconfirm
                title={"Are you sure?"}
                onCancel={() => {}}
                onConfirm={() => handleDeleteLocation(data.id)}
              >
                <i className="ml-1">
                  <img
                    alt="delete"
                    src={DeleteIcon}
                    style={{ width: 25, height: 25 }}
                  />
                </i>
              </Popconfirm>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Name",
      className: "header-white",
      key: "name",
      dataIndex: "name",
      width: 250,
      sorter: (a, b) => sortObjectByString(a, b, "name"),
    },
    {
      title: "Type",
      className: "header-white",
      key: "type",
      width: 200,
      dataIndex: "type",
      sorter: (a, b) => sortObjectByString(a, b, "type"),
    },
    {
      title: "Address",
      width: window.screen.width > 500 ? 450 : 200,
      className: "header-white",
      key: "address",
      render: (rec) => (rec.locationData ? rec.locationData.address : ""),
    },
    {
      title: "Check-ins",
      width: window.screen.width > 500 ? 450 : 200,
      className: "header-white",
      key: "totalCheckIn",
      dataIndex: "totalCheckIn",
      render: (data) =>
        data ? (lastSeason ? data[lastSeason] : data[dayjs().year()]) : "",
    },
  ];

  useEffect(() => {
    if (groupData?.id) {
      fetchLocations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupData?.id]);

  return (
    <div className="col">
      <div className="col" style={{ padding: "12px" }}>
        <ScreenHeader
          title="Locations"
          elementCount={tableData?.length || 0}
          actions={
            <ChantButton
              onClick={() => {
                navigate("/admin/content/locations/create");
              }}
            >
              Add Location
            </ChantButton>
          }
        />

        <div className="locations-description-container">
          <DescriptionText>
            Add locations of your events and stadium. Locations will be used
            across Chant for events (including geolocation check-in), Website,
            and Directory. Want other Location Types? Email support@chant.fan.
          </DescriptionText>
        </div>

        <div className="mt-2">
          <Table
            bordered
            loading={loading}
            columns={columns}
            dataSource={tableData}
            className="table-backdrop"
            bodyStyle={{ backgroundColor: "#ffffff", overflow: "scroll" }}
            pagination={{
              position: ["bottomLeft"],
              defaultPageSize: 100,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 50, 100],
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Locations;
