import React from "react";
import { Button, Popconfirm, Spin } from "antd";
import "./MemberActions.css";

import { getMyId } from "../../../../../services/firebaseService/endPoints/admin/members";
import useMemberActions from "./useMemberActions";
import { AddIcon, DownloadIcon, ReloadIcon } from "../../../../../utils/icons";
import ScreenHeader from "../../../../../CommonComponents/ScreenHeader";

const MemberActions = ({ tableData, addMemberLoading }) => {
  const {
    groupData,
    downloading,
    handleMemberRefresh,
    handleDownloadCsv,
    currentPackages,
    showAddMember,
  } = useMemberActions(tableData);

  return (
    <ScreenHeader
      title={"Members"}
      elementCount={tableData?.length || 0}
      actions={
        <div className="member-actions-buttons">
          <Popconfirm
            title="Download all members list?"
            onConfirm={handleDownloadCsv}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
            trigger="click"
          >
            <div className="icon-container" style={{ cursor: "pointer" }}>
              {downloading ? (
                <Spin style={{ fontSize: "20px" }} />
              ) : (
                <img
                  src={DownloadIcon}
                  alt="icon"
                  style={{ fontSize: "20px" }}
                />
              )}
            </div>
          </Popconfirm>

          <Popconfirm
            title="Refresh Member List?"
            onConfirm={handleMemberRefresh}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <div className="icon-container" style={{ cursor: "pointer" }}>
              {downloading ? (
                <Spin style={{ fontSize: "20px" }} />
              ) : (
                <img src={ReloadIcon} alt="icon" style={{ fontSize: "20px" }} />
              )}
            </div>
          </Popconfirm>
          {currentPackages?.length > 0 && showAddMember && (
            <Popconfirm
              title="This allows you to register a member while bypassing the payment process. Continue?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => {
                let url = `https://group.chant.fan/${groupData?.groupName.replace(/ /g, "").replaceAll("#", "%23").toLowerCase()}/join/addMember?id=${getMyId()}`;
                window.open(url);
              }}
              onCancel={() => {}}
            >
              <Button
                style={{
                  backgroundColor: "var(--primary-background)",
                  color: "var(--primary-text-color)",
                }}
                icon={
                  <img
                    src={AddIcon}
                    alt="add-member"
                    style={{ fontSize: "18px", color: "white" }}
                  />
                }
                loading={addMemberLoading}
                disabled={addMemberLoading}
              >
                Add Member
              </Button>
            </Popconfirm>
          )}
        </div>
      }
    />
  );
};

export default MemberActions;
