import moment from "moment";
import { store } from "../..";
import { applicationError } from "../../redux/actions/error";

class Group {
  constructor() {
    // this.admins = []
    this.country = "";
    this.season = "";
    this.groupName = "";
    this.leagueName = "";
    this.leagueId = "";
    this.clubName = "";
    this.clubId = "";
    this.renewalDate = "";
    this.registrationCode = "";
    this.supportersRegion = "";
    this.supportersCountry = "";
    this.groupType = "";
    this.allowPurchase = "";
    this.allowGlobalChatPost = false;
    this.children = [];
    this.isParent = false;
    this.clubPostAllowed = false;
    this.appIcon = 6;
  }

  toDataSet(data) {
    this.configuration = {};
    this.allowPurchase = !!data.allowPurchase;
    this.allowGlobalChatPost = !!data.allowGlobalChatPost;
    // this.officialSupportersGroup = !!data.officialSupportersGroup
    // this.logoFile = {}
    this.isParent = data.isParent;
    this.clubPostAllowed = data.clubPostAllowed;
    this.status = data.status;
    this.owner = data.owner;
    this.lastMembershipCount = data.lastMembershipCount;
    this.paymentDetails = data.paymentDetails;
    this.appIcon = data.selectedAppIconIndex;

    if (data.selectedAppIconIndex) {
      this.configuration.appIcon = data.selectedAppIconIndex + 1;
    }

    if (data.primaryColor) {
      this.configuration.primaryColor = data.primaryColor;
    }
    if (data.secondaryColor) {
      this.configuration.secondaryColor = data.secondaryColor;
    }
    if (data.stripesColor) {
      this.configuration.stripesColor = data.stripesColor;
    }
    if (data.textPrimaryColor) {
      this.configuration.textPrimaryColor =
        /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(data.textPrimaryColor)
          ? data.textPrimaryColor.toUpperCase()
          : "";
    }
    if (data.textSecondaryColor) {
      this.configuration.textSecondaryColor =
        /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(data.textSecondaryColor)
          ? data.textSecondaryColor.toUpperCase()
          : "";
    }

    if (data.country) {
      this.country = data.country;
    }

    if (data.season) {
      this.season = data.season;
    }

    if (data.groupName) {
      this.groupName = data.groupName;
    }

    if (data.leagueId) {
      this.leagueId = data.leagueId;
      if (Array.isArray(data.leagueList)) {
        let league = data.leagueList.find((league) => {
          return String(league.league_id) === String(data.leagueId);
        });
        if (league) this.leagueName = league.name;
      }
    }

    if (data.clubId) {
      this.clubId = data.clubId;
      if (Array.isArray(data.clubList)) {
        let club = data.clubList.find((club) => {
          return String(club.team_id) === String(data.clubId);
        });
        if (club) this.clubName = club.name;
      }
    }

    if (data.renewalDate) {
      this.renewalDate = data.renewalDate.unix();
    } else {
      this.hasError = { message: "Renewal Date is required" };
      return;
    }

    // if (Array.isArray(data.admins)) {
    //     data.admins.forEach((admin) => {
    //         if (admin.email) this.admins.push({ name: admin.name || "", phoneNumber: admin.phoneNumber || "", email: admin.email })
    //     })
    // }

    if (data.totalMembers) {
      this.totalMembers = data.totalMembers;
    }

    if (data.registrationCode) {
      this.registrationCode = data.registrationCode;
    }

    // if (data.logoFile.name) {
    //     this.logoFile = JSON.parse(JSON.stringify(data.logoFile))
    // } else {
    //     this.hasError = { message: "Please upload a group logo" }
    //     return
    // }

    if (data.supportersRegion) {
      this.supportersRegion = data.supportersRegion;
    }

    if (data.supportersCountry) {
      this.supportersCountry = data.supportersCountry;
    }

    if (data.groupType) {
      this.groupType = data.groupType;
    }

    if (Array.isArray(data.children) && data.children.length > 0) {
      this.children = data.children;
    }

    return;
  }

  fromDataSet(data) {
    this.editConfiguration = {
      imageChanged: false,
      path: data.configuration.path,
      admins: [],
    };
    this.editMode = true;
    this.allowPurchase = !!data.allowPurchase;
    this.allowGlobalChatPost = !!data.allowGlobalChatPost;
    this.primaryColor = "";
    this.secondaryColor = "";
    this.stripesColor = "";
    this.textPrimaryColor = "";
    this.textSecondaryColor = "";
    this.logoFile = { name: "-1" };
    this.logoList = [{ uid: "-1", url: data.configuration.logo }];
    this.clubList = [];
    this.leagueList = [];
    this.seasonList = [];
    this.isParent = !!data.isParent;
    this.clubPostAllowed = !!data.clubPostAllowed;
    this.status = data.status;
    this.owner = data.owner;
    this.lastMembershipCount = data.lastMembershipCount || 0;
    this.paymentDetails = data.paymentDetails || {
      currency: "",
      stripeAccountId: "",
    };
    this.selectedAppIconIndex = Number(data?.configuration?.appIcon || "7") - 1;

    // if (data.admins) {
    //     this.admins = JSON.parse(JSON.stringify(data.admins))
    //     this.editConfiguration.admins = JSON.parse(JSON.stringify(data.admins))
    // }

    if (data.renewalDate) {
      this.renewalDate = moment.unix(data.renewalDate);
    }

    if (data.country) {
      this.country = data.country;
    } else {
      let message = "Country details for group not found";
      store.dispatch(
        applicationError({ message, intensity: "high", show: "true" })
      );
      this.hasError = { message };
    }

    if (data.configuration) {
      this.primaryColor = data.configuration.primaryColor;
      this.secondaryColor = data.configuration.secondaryColor;
      this.stripesColor = data.configuration.stripesColor;
      this.textPrimaryColor = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(
        data.configuration.textPrimaryColor
      )
        ? data.configuration.textPrimaryColor
        : "";
      this.textSecondaryColor = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(
        data.configuration.textSecondaryColor
      )
        ? data.configuration.textSecondaryColor
        : "";
    } else {
      let message = "Configuration details for group not found";
      store.dispatch(
        applicationError({ message, intensity: "high", show: "true" })
      );
      this.hasError = { message };
    }

    if (data.season) {
      this.season = data.season;
    } else {
      let message = "Season details for group not found";
      store.dispatch(
        applicationError({ message, intensity: "high", show: "true" })
      );
      this.hasError = { message };
    }

    if (data.leagueId) {
      this.leagueId = data.leagueId;
    } else {
      let message = "League details for group not found";
      store.dispatch(
        applicationError({ message, intensity: "high", show: "true" })
      );
      this.hasError = { message };
    }

    if (data.clubId) {
      this.clubId = data.clubId;
    } else {
      let message = "Club details for group not found";
      store.dispatch(
        applicationError({ message, intensity: "high", show: "true" })
      );
      this.hasError = { message };
    }

    if (data.registrationCode) {
      this.registrationCode = data.registrationCode;
    }

    if (data.groupName) {
      this.groupName = data.groupName;
    }
    if (data.totalMembers) {
      this.totalMembers = data.totalMembers;
    }

    if (data.supportersCountry) {
      this.supportersCountry = data.supportersCountry;
    }

    if (data.supportersRegion) {
      this.supportersRegion = data.supportersRegion;
    }

    if (data.groupType) {
      this.groupType = data.groupType;
    }

    if (Array.isArray(data.children) && data.children.length > 0) {
      this.children = data.children;
      if (
        data.children.includes("All") ||
        data.children.includes("Official Groups")
      ) {
        this.isGroupSelectionManual = false;
      } else {
        this.isGroupSelectionManual = true;
      }
    }

    return;
  }
}

class Configuration {
  constructor() {
    this.primaryColor = "";
    this.secondaryColor = "";
    this.stripesColor = "";
    this.textPrimaryColor = "";
    this.textSecondaryColor = "";
  }
}

export default Group;
