import React from "react";
import {
  AutoRenewCancelled,
  AutoRenewLink,
  ReceiptIcon,
  TransactionIcon,
} from "../../../utils/icons";
import { Tooltip } from "antd";
import dayjs from "dayjs";

const ReceiptElement = ({
  receiptUrl,
  stripeTransactionUrl,
  subscriptionUrl,
  cancelSubscriptionTimestamp,
}) => {
  return (
    <div>
      {stripeTransactionUrl ? (
        <div className="flex-center" style={{ gap: "6px" }}>
          {receiptUrl && (
            <Tooltip title={"Receipt"}>
              <div className="flex-center">
                <a href={receiptUrl} target="_blank" rel="noopener noreferrer">
                  <img
                    style={{ color: "blue" }}
                    height={24}
                    width={24}
                    src={ReceiptIcon}
                    alt="receiptUrl"
                  />
                </a>
              </div>
            </Tooltip>
          )}
          {stripeTransactionUrl && (
            <Tooltip title={"Payment"}>
              <div className="flex-center">
                <a
                  href={stripeTransactionUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    style={{ color: "blue" }}
                    height={24}
                    width={24}
                    src={TransactionIcon}
                    alt="transactionUrl"
                  />
                </a>
              </div>
            </Tooltip>
          )}

          {cancelSubscriptionTimestamp ? (
            <Tooltip
              title={`Subscription cancelled on ${dayjs(cancelSubscriptionTimestamp).format("YYYY-MM-DD HH:mm:ss")}`}
            >
              <div className="flex-center">
                <img
                  style={{ color: "blue" }}
                  height={24}
                  width={24}
                  src={AutoRenewCancelled}
                  alt="subscriptionCancelledUrl"
                />
              </div>
            </Tooltip>
          ) : subscriptionUrl ? (
            <Tooltip title={"Subscription"}>
              <div className="flex-center">
                <a
                  href={subscriptionUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    style={{ color: "blue" }}
                    height={24}
                    width={24}
                    src={AutoRenewLink}
                    alt="subscriptionUrl"
                  />
                </a>
              </div>
            </Tooltip>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="flex-center">
          <p>Admin</p>
        </div>
      )}
    </div>
  );
};

export default ReceiptElement;
