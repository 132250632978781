import { Select, Table, Tooltip } from "antd";
import React, { useState, useEffect, useCallback } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch, useSelector } from "react-redux";

import { selectCurrentGroupData } from "../../../../redux/selectors/adminData";
import { setGettingStartedItemStatus } from "../../../../redux/actions/adminData";
import {
  GUIDE_GROW,
  TOPIC_SELL_MERCHANDISE,
} from "../../../../constants/gettingStarted";
import { useNavigate } from "react-router-dom";
import { getStoreItems } from "../../../../services/firebaseService/endPoints/admin/store";
import { GripVertical } from "../../../../utils/icons";
import { getCurrencyUnicode } from "../../../../helperFunctions/util";
import DraggableBodyRow from "../../ourChants/DraggableBodyRow";
import "./Merchandise.css";
import ResponsiveMobileTab from "../../../../CommonComponents/ResponsiveMobileTab/ResponsiveMobileTab";
import ChantButton from "../../../../CommonComponents/ChantButton/ChantButton";
import { collection, doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../services/firebaseService/connection";

const Merchandise = () => {
  const groupData = useSelector(selectCurrentGroupData);

  const [needsUpdate, setNeedsUpdate] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState([]);
  const [tableDataLoading, setTableDataLoading] = useState([]);

  const STATUS_FILTERS = ["Active", "Inactive", "All"];

  const [status, setStatus] = useState("Active");
  const [filteredTableData, setFilteredTableData] = useState([]);

  const updateTable = useCallback((tableData) => {
    setTableData(tableData);
  }, []);

  const fetchData = async () => {
    try {
      setTableDataLoading(true);
      const items = await getStoreItems(groupData.id);

      setTableData(items);
      if (items?.length) {
        dispatch(
          setGettingStartedItemStatus(
            groupData.id,
            GUIDE_GROW,
            TOPIC_SELL_MERCHANDISE,
            true
          )
        );
      }
    } catch (error) {
      setTableData([]);
    } finally {
      setTableDataLoading(false);
    }
  };

  useEffect(() => {
    if (groupData.id) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupData.id]);

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      if (
        !Array.isArray(filteredTableData) ||
        filteredTableData.length === 0 ||
        dragIndex < 0 ||
        hoverIndex < 0 ||
        dragIndex >= filteredTableData.length ||
        hoverIndex >= filteredTableData.length ||
        dragIndex === hoverIndex
      ) {
        return;
      }

      const newData = JSON.parse(JSON.stringify(filteredTableData));

      const newIndex = newData[hoverIndex].sortIndex;

      if (dragIndex > hoverIndex) {
        //moving item up
        const startIndex = hoverIndex;
        const endIndex = dragIndex;

        for (let i = startIndex; i < endIndex; i++) {
          newData[i].sortIndex = newData[i + 1].sortIndex;
        }
      } else {
        //moving item down
        const startIndex = hoverIndex;
        const endIndex = dragIndex;

        for (let i = startIndex; i > endIndex; i--) {
          newData[i].sortIndex = newData[i - 1].sortIndex;
        }
      }

      newData[dragIndex].sortIndex = newIndex;

      try {
        updateTable(newData);
        setNeedsUpdate(true);
      } catch (error) {
        console.error("Error updating table data:", error);
      }
    },
    [filteredTableData, updateTable, setNeedsUpdate]
  );

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  let groupCurrencyISO = groupData.paymentDetails
    ? groupData.paymentDetails.currency || "USD"
    : "USD";
  let groupCurrency = getCurrencyUnicode(groupCurrencyISO);

  const columns = [
    {
      width: 80,
      render: (data) => {
        return (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <img src={GripVertical} alt="*" />
            {data && data.id && (
              <i
                className="fa fa-pencil cursor-pointer ml-3"
                onClick={() => {
                  navigate(`/admin/store/edit/${data.id}`);
                }}
              ></i>
            )}
          </div>
        );
      },
    },
    {
      title: "Item",
      className: "header-white",
      width: 250,
      dataIndex: "name",
    },
    {
      title: "Status",
      className: "header-white",
      width: 100,
      dataIndex: "status",
    },
    {
      title: "Display On",
      className: "header-white",
      width: 150,
      dataIndex: "displayOn",
      render: (list, record) =>
        record.status === "live" ? (
          <ul style={{ listStyleType: "none" }}>
            {list.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        ) : (
          ""
        ),
    },
    {
      title: "Quantity",
      className: "header-white",
      dataIndex: "comboValues",
      render: (data, record) => {
        let quantity = 0;
        if (data) {
          quantity = data.reduce((acc, val) => acc + val.quantity, 0);
        } else {
          quantity = record.quantity;
        }
        return !isNaN(quantity) ? quantity : "";
      },
    },
    {
      title: "Sold",
      className: "header-white",
      dataIndex: "comboValues",
      render: (data, record) => {
        let sold = 0;
        if (data) {
          sold = data.reduce((acc, val) => acc + (val.sold || 0), 0);
        } else {
          sold = record.sold;
        }

        return <span>{!isNaN(sold) ? sold : ""}</span>;
      },
    },
    {
      title: "Remaining",
      className: "header-white",
      dataIndex: "comboValues",
      render: (data, record) => {
        let quantity = 0;
        if (data) {
          quantity = data.reduce((acc, val) => acc + val.quantity, 0);
        } else {
          quantity = record.quantity;
        }

        let sold = 0;
        if (data) {
          sold = data.reduce((acc, val) => acc + val.sold, 0);
        } else {
          sold = record.sold;
        }

        return (
          <span>
            {quantity && sold ? quantity - sold : quantity ? quantity : ""}
          </span>
        );
      },
    },
    {
      title: (
        <span>
          Revenue
          <Tooltip
            title={
              <div style={{ maxWidth: 400 }}>
                <span>
                  'Included' items (merchandise that is 'included' in a
                  membership packages) is not represented here as revenue. That
                  revenue is displayed as part of the membership package in
                  Member table.
                </span>
              </div>
            }
            placement="topLeft"
          >
            <span className="border-0">
              &nbsp;<i className="fa fa-question-circle-o"></i>
            </span>
          </Tooltip>
        </span>
      ),
      className: "header-white",
      dataIndex: "revenue",
      render: (data) => {
        let revenue = data || 0;
        return (
          <span>
            {!isNaN(revenue)
              ? `${groupCurrency}${revenue.toFixed(
                  Math.round(revenue) === revenue ? 0 : 2
                )}`
              : ""}
          </span>
        );
      },
    },
  ];

  let newItem = tableData.filter((elem) => elem.sortIndex === 0);

  let dataSource = tableData.sort(
    (row1, row2) => row1.sortIndex - row2.sortIndex
  );

  if (newItem.length > 0) {
    dataSource.forEach((elem) => {
      elem.sortIndex += 1;
    });
  }

  const applySortOrder = async (idOrderMap, groupId) => {
    if (!idOrderMap || !groupId) {
      throw new Error("Invalid parameters: idOrderMap or groupId is missing.");
    }

    const collectionRef = collection(db, "store", groupId, "items");

    try {
      const writePromises = Object.entries(idOrderMap).map(([id, sortIndex]) =>
        updateDoc(doc(collectionRef, id), { sortIndex })
      );
      await Promise.all(writePromises);
    } catch (error) {
      console.error("Error applying sort order:", error);
      throw error;
    }
  };

  useEffect(() => {
    applyFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, status]);

  const applyFilter = () => {
    const filteredData = dataSource.filter((item) => {
      if (status === "Active" && item.status !== "live") {
        return false;
      }

      if (status === "Inactive" && item.status !== "inactive") {
        return false;
      }
      return true;
    });
    setFilteredTableData(filteredData);
  };

  const handleFilterChange = (filter) => {
    setStatus(filter);
  };

  const [updateChangesLoading, setUpdateChangesLoading] = useState(false);

  const updateSortOrder = async () => {
    if (!filteredTableData || !groupData) {
      console.error("Missing required data for updating sort order");
      return;
    }

    try {
      setUpdateChangesLoading(true);

      let idOrderMap = filteredTableData.reduce((acc, val) => {
        acc[val.id] = val.sortIndex;
        return acc;
      }, {});

      await applySortOrder(idOrderMap, groupData.id);
      fetchData();
      setNeedsUpdate(false);
    } catch (error) {
      console.error("Error updating sort order:", error);
    } finally {
      setUpdateChangesLoading(false);
    }
  };

  return (
    <div className="merchandise-container">
      <nav className="merchandise-nav">
        <ul
          style={{
            listStyle: "none",
            // padding: "2rem 3rem 0rem",
            padding: "0",
            paddingBottom: "1rem",
            margin: 0,
            display: "flex",
          }}
        >
          <ResponsiveMobileTab
            menuItems={[
              {
                key: "merchandise",
                label: "Merchandise",
              },
            ]}
            param={"merchandise"}
            primaryColor="var(--primary-background)"
            tabIndex={0}
          />
        </ul>

        <div style={{ display: "flex", gap: "6px" }}>
          {needsUpdate && (
            <ChantButton
              onClick={updateSortOrder}
              loading={updateChangesLoading}
            >
              Update Changes
            </ChantButton>
          )}

          <ChantButton
            onClick={() => {
              navigate("/admin/store/create");
            }}
          >
            Add Item
          </ChantButton>
        </div>
      </nav>

      <Select
        className="event-filter-input"
        defaultActiveFirstOption
        popupMatchSelectWidth={false}
        options={STATUS_FILTERS?.map((item) => ({
          value: item,
          label: <span>{item}</span>,
        }))}
        value={status}
        onChange={(val) => handleFilterChange(val)}
      />

      <div className="mt-3">
        <DndProvider backend={HTML5Backend}>
          <Table
            bordered
            columns={columns}
            loading={tableDataLoading || updateChangesLoading}
            dataSource={filteredTableData}
            components={components}
            onRow={(data, index) => {
              return {
                index,
                moveRow,
              };
            }}
            bodyStyle={{
              backgroundColor: "#ffffff",
            }}
            pagination={{
              position: ["bottomLeft"],
              defaultPageSize: 50,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 50, 100],
            }}
            className="table-backdrop"
            scroll={{ x: "max-content" }}
          />
        </DndProvider>
      </div>
    </div>
  );
};

export default Merchandise;
