import React, { useEffect, useState } from "react";
import { MonthRangePicker } from "../../gallery/Gallery";
import { Tabs } from "antd";
import { getImages } from "../../../../services/firebaseService/endPoints/admin/gallery";
import { postUpdateCDNUtil } from "../../../../helperFunctions/util";
import { selectCurrentGroupData } from "../../../../redux/selectors/adminData";
import { useSelector } from "react-redux";
import { doc, getDoc, setDoc, writeBatch } from "firebase/firestore";
import { db } from "../../../../services/firebaseService/connection";
import logger from "../../../../utils/logger";
import DescriptionText from "../../../../CommonComponents/DescriptionText";
import TextArea from "antd/es/input/TextArea";
import ChantButton from "../../../../CommonComponents/ChantButton/ChantButton";
import TabPane from "antd/es/tabs/TabPane";
import Images from "./Images";

const WebsiteGallery = () => {
  const [imageData, setImageData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [tab, setTab] = useState("all");
  const [galleryIntro, setGalleryIntro] = useState("");
  const [showAllPublicPhotos, setShowAllPublicPhotos] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [imagesLoading, setImagesLoading] = useState(false);
  const [selectedImages, setSelectedImages] = useState({});
  const [webImages, setWebImages] = useState({});

  const groupData = useSelector(selectCurrentGroupData);

  const handleMonthRangeChange = (start, end) => {
    let data = filteredData;

    if (start && end) {
      data = data.filter((data) => {
        let date = new Date(data.uploadDate * 1000);
        let month = date.getMonth();
        let year = date.getFullYear();

        let totalMonths = year * 12 + month;

        return (
          totalMonths >= start.year * 12 + start.month &&
          totalMonths <= end.year * 12 + end.month
        );
      });

      setFilteredData(data);
    } else {
      data = imageData;
      setFilteredData(data);
    }
  };

  const handleSelectImg = (value, id) => {
    setSelectedImages((prev) => ({ ...prev, [id]: value }));
  };

  const changeTab = (tabName) => {
    setTab(tabName);
  };

  const fetchWebData = async () => {
    try {
      let docRef = doc(db, "website", groupData.id);
      let docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let docData = docSnap.data();
        setGalleryIntro(docData.galleryIntro);
        setShowAllPublicPhotos(docData.showAllPublicPhotos || false);
      }
    } catch (error) {
      console.error("Error fetching web data:", error);
    }
  };

  const saveGalleryIntro = async () => {
    try {
      setIsSaving(true);
      let docRef = doc(db, "website", groupData.id);

      await setDoc(
        docRef,
        { galleryIntro, showAllPublicPhotos },
        { merge: true }
      );

      await postUpdateCDNUtil(groupData.id, "website");
      await fetchWebData();
    } catch (error) {
      console.error("Error saving gallery intro:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const fetchImages = async () => {
    try {
      setImagesLoading(true);
      const images = await getImages(groupData.id);

      setImageData(images);
      setFilteredData(images);
      const imagesShow = images.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.id]: curr?.showOnWeb || false,
        }),
        {}
      );

      setWebImages(imagesShow);
    } catch (error) {
      logger.error("Unable to fetch images");
    } finally {
      setImagesLoading(false);
    }
  };

  const [stateChanged, setStateChanged] = useState(false);

  const checkForChanges = () => {
    return Object.keys(selectedImages).some((id) => {
      // If the new value matches the original value, it's not a change
      return selectedImages[id] !== webImages[id];
    });
  };

  const getChangedImagesOnly = (selectedImages, webImages) => {
    return Object.entries(selectedImages).reduce((changes, [id, value]) => {
      if (value !== webImages[id]) {
        changes[id] = value;
      }
      return changes;
    }, {});
  };

  const [updateImgLoading, setUpdateImgLoading] = useState(false);

  const changeShowOnWeb = async () => {
    const batch = writeBatch(db);

    const changedImages = getChangedImagesOnly(selectedImages, webImages);

    Object.entries(changedImages).forEach(([key, value]) => {
      const imageRef = doc(db, "gallery", groupData.id, "images", key);
      batch.update(imageRef, { showOnWeb: value });
    });

    try {
      setUpdateImgLoading(true);
      await batch.commit();
      postUpdateCDNUtil(groupData.id);
      setSelectedImages({});
      fetchImages();
    } catch (error) {
      logger.error("Error updating showOnWeb for multiple images:", error);
      throw error; // Propagate the error
    } finally {
      setUpdateImgLoading(false);
    }
  };

  useEffect(() => {
    const stateChanged = checkForChanges();

    setStateChanged(stateChanged);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedImages, webImages]);

  useEffect(() => {
    if (groupData.id) {
      fetchImages();
      fetchWebData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupData.id]);

  return (
    <div
      style={{
        padding: "15px 30px",
        backgroundColor: "#ffffff",
        // boxShadow: "0px 3px 10px 1px",
        marginTop: 30,
        borderRadius: "0.25rem",
      }}
    >
      <DescriptionText>
        Note: You must select at least 7 photos for the gallery carousel to
        appear. For optimal website design select images in multiples of 7.
      </DescriptionText>

      <div className="mt-4">
        <h4>Intro</h4>
        <TextArea
          showCount
          maxLength={1000}
          placeholder="Gallery Intro..."
          value={galleryIntro}
          name="galleryIntro"
          onChange={(e) => {
            setGalleryIntro(e.target.value);
          }}
        />
        <ChantButton
          onClick={saveGalleryIntro}
          loading={isSaving}
          className="mt-2"
        >
          Save
        </ChantButton>
      </div>

      <hr />

      <div
        className="mt-4"
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <MonthRangePicker onChange={handleMonthRangeChange} />
      </div>

      <div style={{ display: "flex", gap: "2rem" }}>
        <Tabs
          defaultActiveKey="all"
          activeKey={tab}
          onChange={changeTab}
          className="text-[15px]"
        >
          <TabPane tab="All" key="all" />
          <TabPane tab="Selected" key="selected" />
        </Tabs>

        <div className="flex-center">
          {stateChanged && (
            <ChantButton
              onClick={changeShowOnWeb}
              loading={updateImgLoading}
              disabled={updateImgLoading}
            >
              Update
            </ChantButton>
          )}
        </div>
      </div>

      <Images
        imagesLoading={imagesLoading}
        webImages={webImages}
        filteredData={filteredData}
        tab={tab}
        selectedImages={selectedImages}
        handleSelectImg={handleSelectImg}
      />
    </div>
  );
};

export default WebsiteGallery;
