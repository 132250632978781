import React, { useEffect, useState } from "react";
import { Modal } from "antd";

const AppIconModal = ({
  showIconModal,
  handleModalOpen,
  handleSelectIcon,
  selectedAppIconIndex,
}) => {
  const icons = Array.from({ length: 20 }, (_, i) =>
    require(`../../../../images/app-icons/${i + 1}.png`)
  );

  const [iconIndex, setIconIndex] = useState(selectedAppIconIndex);

  const handleOnOk = () => {
    handleSelectIcon(iconIndex);
    handleModalOpen(false);
  };

  useEffect(() => {
    setIconIndex(selectedAppIconIndex);
  }, [selectedAppIconIndex]);

  return (
    <Modal
      title="Select App Icon"
      open={showIconModal}
      onOk={handleOnOk}
      onCancel={() => handleModalOpen(false)}
    >
      <div className="grid-container">
        {icons.map((icon, index) => (
          <div
            className={`grid-item ${index === iconIndex && "selected-icon"}`}
            onClick={() => setIconIndex(index)}
          >
            <img
              key={index}
              alt={`app-icon-${index + 1}`}
              height={80}
              width={80}
              src={icon}
            />
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default AppIconModal;
