import React, { useEffect, useState } from "react";

import { Avatar, Tag, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  GUIDE_ONBOARD,
  TOPIC_ASSIGN_LEADERS,
} from "../../../../constants/gettingStarted";
import {
  CHAPTER_FILTER,
  FANS_NON_MEMBERS_FILTER,
  INACTIVE_FILTER,
  LEADERS_FILTER,
  LOCKED_ARCHIVED_FILTER,
  MEMBERS_ALL_FILTER,
  MEMBERS_NOT_ON_CHANT_FILTER,
  MEMBERS_ON_AUTO_RENEWAL_FILTER,
  MEMBERS_ON_CHANT_FILTER,
  OFFICIAL_CLUB_MEMBER_FILTER,
  OTHER_FILTER,
  PACKAGE_FILTER,
  RECEIVE_EMAILS_NO_FILTER,
  RECEIVE_EMAILS_YES_FILTER,
  SEASON_TICKET_HOLDER_FILTER,
  STATUS_ALL_FILTER,
  VOLUNTEER_NO_FILTER,
  VOLUNTEER_YES_FILTER,
} from "../../../../constants/members";
import {
  getMinPackageSeasonFromTimeline,
  postUpdateCDNUtil,
} from "../../../../helperFunctions/util";
import { selectCurrentGroupData } from "../../../../redux/selectors/adminData";
import { fetchFormLayout } from "../../../../services/api/packages";
import useGlobalStore from "../../../../store/store";
import {
  sortObjectByNumber,
  sortObjectByString,
} from "../../../../utils/helper";
import { AutoRenewIcon, EditIcon, NoteIcon } from "../../../../utils/icons";
import { isEmailValid } from "../../../../utils/validation";

import {
  collection,
  deleteField,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { setGettingStartedItemStatus } from "../../../../redux/actions/adminData";
import { selectAllGroups } from "../../../../redux/selectors/adminData";
import { getMyId } from "../../../../services/firebaseService/endPoints/admin/members";
import logger from "../../../../utils/logger";

import { LockOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { fetchMembers } from "../../../../services/api/members";
import { db } from "../../../../services/firebaseService/connection";
import usePackages from "../../../hooks/usePackages";
import useMembersData from "./useMembersData";
import HelpTooltip from "../../../../CommonComponents/HelpTooltip";
import axios from "axios";

const useMembers = () => {
  const groupData = useSelector(selectCurrentGroupData);
  const allGroups = useSelector(selectAllGroups);

  const { group: paramsGroup, id: paramsId } = useParams();
  const [registrationData, setRegistrationData] = useState(null);
  const [idToAccept, setIdToAccept] = useState("");
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [addMemberLoading, setAddMemberLoading] = useState(false);
  const [verifyAddMemberLoading, setVerifyAddMemberLoading] = useState(false);

  const dispatch = useDispatch();

  const { currentPackages, activePackages, packagesBySeason } = usePackages();

  const { formLayout } = useGlobalStore((state) => state.formLayout);

  const { membersList, membersLoading } = useMembersData();

  const [tableData, setTableData] = useState();
  const [filteredTableData, setFilteredTableData] = useState();

  const [filters, setFilters] = useState({
    searchTerm: "",
    status: STATUS_ALL_FILTER,
    other: OTHER_FILTER,
    package: PACKAGE_FILTER,
    chapter: CHAPTER_FILTER,
    joinedSince: "",
  });

  useEffect(() => {
    if (membersList) {
      const tableData = membersList
        .map(({ children, ...item }) => ({ ...item, childMembers: children }))
        .sort((a, b) => a.name.localeCompare(b.name));

      setTableData(tableData);
      setFilteredTableData(tableData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membersList]);

  const handleUrlRegistration = async () => {
    try {
      setVerifyAddMemberLoading(true);
      const docRef = doc(
        db,
        "transaction_record",
        paramsGroup,
        "records",
        paramsId
      );
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        const myId = getMyId();

        if (
          data?.referrerId === myId &&
          allGroups.hasOwnProperty(paramsGroup)
        ) {
          setRegistrationData(data);
        } else {
          resetAcceptState();
        }
      } else {
        resetAcceptState();
      }
    } catch (err) {
      logger.error(`Error while fetching data: ${err.message}`);
      resetAcceptState();
    } finally {
      setVerifyAddMemberLoading(false);
    }
  };

  const resetAcceptState = () => {
    setIdToAccept("");
    setShowAddMemberModal(false);
  };

  const resetMemberFilters = () => {
    setFilters({
      searchTerm: "",
      status: STATUS_ALL_FILTER,
      other: OTHER_FILTER,
      package: PACKAGE_FILTER,
      chapter: CHAPTER_FILTER,
      joinedSince: "",
    });
  };

  const addMember = async () => {
    const url =
      "https://us-central1-chant2019.cloudfunctions.net/stripe2/writeDataToFirebase";
    const metadata = registrationData?.data;
    const groupId = groupData?.id;

    const reqBody = {
      metadata: metadata,
      groupId: groupId,
    };

    try {
      setAddMemberLoading(true);
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      fetchMembers(groupData.id);
      setIdToAccept("");
      setShowAddMemberModal(false);
      setRegistrationData({});
    } catch (err) {
      logger.error(err);
    } finally {
      setAddMemberLoading(false);
    }
  };

  useEffect(() => {
    if (!membersList) {
      fetchMembers(groupData?.id);
    }

    if (!formLayout?.[groupData?.id]) {
      fetchFormLayout(groupData?.id);
    }

    resetMemberFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupData?.id]);

  const handleFilterChange = (type, selectedValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: selectedValue,
    }));
  };

  const handleDropdownChange = (type, selectedValue) => {
    handleFilterChange(type, selectedValue);
  };

  const handleSearchInputChange = (value) => {
    handleFilterChange("searchTerm", value);
  };

  const applyFilters = (data) => {
    return data.filter((item) => {
      if (filters?.searchTerm) {
        const searchTermLower = filters.searchTerm.toLowerCase();
        const nameLower = item?.name?.toLowerCase();
        const emailLower = item?.email?.toLowerCase();

        if (
          !(
            nameLower?.includes(searchTermLower) ||
            emailLower?.includes(searchTermLower)
          )
        ) {
          return false;
        }
      }

      if (
        filters.joinedSince &&
        dayjs
          .unix(item?.registrationDate)
          .isBefore(dayjs.unix(filters.joinedSince))
      ) {
        return false;
      }

      if (
        filters.package &&
        filters.package.length > 0 &&
        filters.package !== PACKAGE_FILTER
      ) {
        let packageSeason = "";

        if (item?.packageSeason) {
          packageSeason = item?.packageSeason;
        } else {
          const pkgId = filters?.package?.split("|")?.[0];

          const pkgDetails = currentPackages?.find((pkg) => pkg?.id === pkgId);

          if (pkgDetails) {
            packageSeason = getMinPackageSeasonFromTimeline(
              pkgDetails?.packageTimeline
            );
          }
        }

        const pkgKey = item?.package
          ? packageSeason
            ? `${item?.package}|${packageSeason}`
            : item?.package
          : "";
        if (pkgKey !== filters?.package) {
          return false;
        }
      }

      if (
        filters.chapter &&
        filters.chapter !== CHAPTER_FILTER &&
        item?.chapter !== filters.chapter
      ) {
        return false;
      }

      if (
        filters.other === OFFICIAL_CLUB_MEMBER_FILTER &&
        !item.officialClubMember
      ) {
        return false;
      }

      if (
        filters.other === SEASON_TICKET_HOLDER_FILTER &&
        (!item.seasonTicketHolder ||
          Object.keys(item.seasonTicketHolder || {}).length === 0 ||
          Object.keys(item.seasonTicketHolder || {})
            .map((key) => item.seasonTicketHolder[key])
            .includes(""))
      ) {
        return false;
      }
      if (
        filters.other === VOLUNTEER_YES_FILTER &&
        !item.shareVolunteerOpportunities
      ) {
        return false;
      }

      if (
        filters.other === VOLUNTEER_NO_FILTER &&
        item.shareVolunteerOpportunities
      ) {
        return false;
      }
      if (
        filters.other === RECEIVE_EMAILS_YES_FILTER &&
        !item.joinedEmailList
      ) {
        return false;
      }
      if (filters.other === RECEIVE_EMAILS_NO_FILTER && item.joinedEmailList) {
        return false;
      }
      // if (
      //   filters.other === DONATED_FILTER &&
      //   (!item.donationAmount ||
      //     !item.donationAmount.value ||
      //     item.donationAmount.value === "0")
      // ) {
      //   return false;
      // }

      if (filters.status === STATUS_ALL_FILTER && item.lockStatus) {
        return false;
      }

      if (filters.status === MEMBERS_ALL_FILTER) {
        if (item.lockStatus) {
          return false;
        } else {
          if (groupData.requireMembershipPackage) {
            if (
              !(
                item.package &&
                item.package?.length > 0 &&
                item.packageSeason &&
                activePackages.find(
                  (pkg) =>
                    pkg.key === `${item.package}|${item.packageSeason}` &&
                    item.userRole !== "fan"
                )
              )
            ) {
              return false;
            }
          } else {
            if (item.userRole === "fan") {
              return false;
            }
          }
        }
      }

      if (filters.status === MEMBERS_ON_CHANT_FILTER) {
        if (item.lockStatus) {
          return false;
        } else {
          if (groupData.requireMembershipPackage) {
            if (
              !(
                item.package &&
                item.package?.length > 0 &&
                item.packageSeason &&
                activePackages.find(
                  (pkg) =>
                    pkg.key === `${item.package}|${item.packageSeason}` &&
                    item.userRole !== "fan"
                ) &&
                item.userRole !== "fan" &&
                item.joinedChant &&
                item.joinedChant !== "No"
              )
            ) {
              return false;
            }
          } else {
            if (
              item.userRole === "fan" ||
              !item.joinedChant ||
              item.joinedChant === "No"
            ) {
              return false;
            }
          }
        }
      }

      if (filters.status === MEMBERS_NOT_ON_CHANT_FILTER) {
        if (item.lockStatus) {
          return false;
        } else {
          if (groupData.requireMembershipPackage) {
            if (
              !(
                item.package &&
                item.package?.length > 0 &&
                item.packageSeason &&
                activePackages.find(
                  (pkg) =>
                    pkg.key === `${item.package}|${item.packageSeason}` &&
                    item.userRole !== "fan"
                ) &&
                item.userRole !== "fan" &&
                (!item.joinedChant || item.joinedChant === "No")
              )
            ) {
              return false;
            }
          } else {
            if (
              item.userRole === "fan" ||
              item.joinedChant ||
              item.joinedChant !== "No"
            ) {
              return false;
            }
          }
        }
      }

      if (filters.status === MEMBERS_ON_AUTO_RENEWAL_FILTER) {
        if (item?.subscriptionDetail?.status !== "active") {
          return false;
        }
      }

      if (
        filters.status === LEADERS_FILTER &&
        (item.lockStatus ||
          item.userRole === "member" ||
          item.userRole === "fan" ||
          !item.userRole)
      ) {
        return false;
      }

      if (
        filters.status === FANS_NON_MEMBERS_FILTER &&
        (item.lockStatus || item.userRole !== "fan")
      ) {
        return false;
      }
      if (filters.status === INACTIVE_FILTER) {
        if (item.lockStatus) {
          return false;
        } else {
          if (groupData.requireMembershipPackage) {
            if (item.userRole === "admin" || item.userRole === "fan") {
              return false;
            }

            if (
              item.package &&
              item.package?.length > 0 &&
              item.packageSeason &&
              activePackages.find(
                (pkg) =>
                  pkg.key === `${item.package}|${item.packageSeason}` &&
                  item.userRole !== "fan"
              )
            ) {
              return false;
            }
          } else {
            return false;
          }
        }
      }
      if (filters.status === LOCKED_ARCHIVED_FILTER && !item.lockStatus) {
        return false;
      }

      return true;
    });
  };

  const updateEmailInUsers = async (memberId, updatedEmail) => {
    try {
      const memberDocRef = doc(db, "users", memberId);
      const docSnapshot = await getDoc(memberDocRef);

      if (docSnapshot.exists()) {
        await updateDoc(memberDocRef, { email: updatedEmail });
      } else {
        logger.log("No such document in users collection!");
      }
    } catch {
      logger.error("Failed to update users collection email!");
    }
  };

  const updateEmailListInInvites = async (groupId, oldEmail, updatedEmail) => {
    try {
      const docRef = doc(db, "invite_emails", groupId);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        let emails = docSnapshot.data().emails || [];
        const emailIndex = emails.indexOf(oldEmail);

        if (emailIndex !== -1) {
          emails[emailIndex] = updatedEmail;
          await updateDoc(docRef, { emails });
        } else {
          logger.log("Email not found in the invite_emails list.");
        }
      } else {
        logger.log("No such document in invite_emails!");
      }
    } catch (error) {
      logger.error("Failed to update invite email details: ", error);
    }
  };

  useEffect(() => {
    if (tableData?.length > 0) {
      const filteredData = applyFilters(tableData);
      setFilteredTableData(filteredData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, tableData]);

  const [editableRecordId, setEditableRecordId] = useState("");
  const [editableRecord, setEditableRecord] = useState({});
  const [linkedMember, setLinkedMember] = useState({});

  const handleModalOpen = async (id, familyMembershipId) => {
    if (id) {
      const selectedRecord = filteredTableData.find((item) => item.id === id);
      setEditableRecord(selectedRecord);
    } else {
      setEditableRecordId("");
      setEditableRecord({});
    }

    if (familyMembershipId) {
      const linkedMember = membersList?.find(
        (member) =>
          member?.familyMembershipId === familyMembershipId && member.id !== id
      );
      setLinkedMember(linkedMember);
    } else {
      setLinkedMember(null);
    }

    setEditableRecordId(id);
  };

  const unsubscribeFromNotifications = async (
    groupId,
    clubId,
    leagueId,
    userId,
    userRole
  ) => {
    try {
      await axios.post(
        `https://us-central1-chant2019.cloudfunctions.net/notification/unSubscribeToTopics`,
        {
          groupId,
          clubId,
          leagueId,
          userId: String(userId),
          userRole,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      logger.error("Failed to unsubscribe");
    }
  };

  const subscribeToNotifications = async (
    groupId,
    clubId,
    leagueId,
    userId,
    userRole
  ) => {
    try {
      await axios.post(
        `https://us-central1-chant2019.cloudfunctions.net/notification/subscribeToTopics`,
        {
          groupId,
          clubId,
          leagueId,
          userId: String(userId),
          userRole,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      logger.error("Failed to subscribe");
    }
  };

  const saveDetail = async (updatedData, id, email) => {
    if (updatedData?.lockStatus === true) {
      await unsubscribeFromNotifications(
        groupData?.id,
        groupData?.clubId,
        groupData?.leagueId,
        id,
        editableRecord?.userRole
      );
    }

    if (
      editableRecord?.lockStatus === true &&
      updatedData?.lockStatus === false
    ) {
      await subscribeToNotifications(
        groupData?.id,
        groupData?.clubId,
        groupData?.leagueId,
        id,
        updatedData?.userRole || editableRecord?.userRole
      );
    }

    if (
      editableRecord?.userRole &&
      updatedData?.userRole &&
      updatedData?.userRole?.length > 0 &&
      editableRecord?.userRole !== updatedData?.userRole
    ) {
      await unsubscribeFromNotifications(
        groupData?.id,
        groupData?.clubId,
        groupData?.leagueId,
        id,
        editableRecord?.userRole
      );

      await subscribeToNotifications(
        groupData?.id,
        groupData?.clubId,
        groupData?.leagueId,
        id,
        updatedData?.userRole
      );
    }

    if (updatedData.fullName) {
      updatedData = {
        ...updatedData,
        name: updatedData.fullName,
      };
    }

    if (updatedData.email === "") {
      alert("Email cannot be empty");
      return;
    }

    if (
      updatedData?.email &&
      updatedData?.email?.length > 0 &&
      !isEmailValid(updatedData.email)
    ) {
      alert("Please enter a valid Email ID");
      return;
    }

    if (updatedData.fullName === "") {
      alert("Name cannot be empty");
      return;
    }

    setFilteredTableData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, ...updatedData } : item
      )
    );

    if (updatedData?.userRole === "admin") {
      dispatch(
        setGettingStartedItemStatus(
          groupData?.id,
          GUIDE_ONBOARD,
          TOPIC_ASSIGN_LEADERS,
          true
        )
      );
    }

    // if inv emails -> update inv emails
    const inviteEmailsDocSnap = await getDocs(
      query(
        collection(db, "invite_emails", groupData?.id, "userDetails"),
        where("email", "==", email)
      )
    ).then((snap) => {
      return snap.docs[0];
    });

    if (updatedData.hasOwnProperty("package")) {
      let selectedPackage;
      const season = updatedData?.packageSeason;

      if (season) {
        selectedPackage = currentPackages?.find((p) => {
          return (
            p.packageTimeline &&
            season in p.packageTimeline &&
            p.id == updatedData?.package
          );
        });
      }
      if (updatedData.package) {
        selectedPackage = currentPackages?.find(
          (p) => p.id === updatedData?.package
        );
      }

      updatedData = {
        ...updatedData,
        packageSeason: season
          ? String(season)
          : String(selectedPackage?.season || ""),
        membershipExpiryDate: selectedPackage?.packageExpiryDate || "",
      };
    }

    if (inviteEmailsDocSnap && inviteEmailsDocSnap.exists()) {
      let data = inviteEmailsDocSnap.data() || {};

      let updateObj = {
        ...data,
        ...updatedData,
      };

      if (!updatedData.tshirtSize) {
        delete updateObj.tshirtSize;
      }

      await updateDoc(inviteEmailsDocSnap.ref, { ...updateObj }).catch(
        (err) => {
          logger.error(
            "Error while updating userDetails in invite_emails",
            err
          );
        }
      );

      // update email in invite_emails collection
      if (updatedData.email) {
        await updateEmailListInInvites(groupData?.id, email, updatedData.email);
      }
    }
    if (typeof id === "string") {
      // update email in users collection
      const usersDocRef = doc(db, "users", id);
      const usersDocSnapshot = await getDoc(usersDocRef);

      if (updatedData.email && usersDocSnapshot && usersDocSnapshot.exists()) {
        await updateEmailInUsers(id, updatedData.email);
      }

      if (!updatedData.tshirtSize) {
        updatedData.tshirtSize = deleteField();
      }

      if (updatedData.hasOwnProperty("hometown")) {
        let homeTown = updatedData.hometown;
        if (!homeTown) {
          homeTown = "";
        }
        updateDoc(doc(db, "users", id), { homeTown });

        delete updatedData.homeTown;
      }

      // update email and other data in usergroup collection
      updateDoc(doc(db, "userGroup", groupData?.id, "members", id), {
        ...updatedData,
      });

      if (updatedData.userRole) {
        postUpdateCDNUtil(groupData?.id);
      }

      let websiteCollectionRef = doc(db, "website", groupData?.id);
      if (updatedData.title) {
        getDoc(websiteCollectionRef).then((doc) => {
          if (doc.exists() && doc.data().about) {
            let about = doc.data().about;
            let leadership = about.leadership;
            if (leadership && Array.isArray(leadership.leaders)) {
              let requiredDoc = leadership.leaders.find(
                (value) => value.id === id
              );
              if (requiredDoc) {
                requiredDoc.title = updatedData.title;
                websiteCollectionRef.update({ about });
              }
            }
          }
        });
      }
    }

    handleModalOpen();
  };

  useEffect(() => {
    if (paramsId && paramsId?.length > 0) {
      setIdToAccept(paramsId);
      setShowAddMemberModal(true);
      handleUrlRegistration();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let columns = [
    {
      title: "Actions",
      width: 100,
      align: "center",
      render: (data) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {data && data.id && (
              <i
                className="ml-3"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  handleModalOpen(data.id, data.familyMembershipId)
                }
              >
                <img
                  src={EditIcon}
                  alt="edit-member"
                  style={{ width: 25, height: 25 }}
                />
              </i>
            )}
          </div>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => sortObjectByString(a, b, "name"),
      render: (name, record) => {
        const nameInitials = name
          .split(" ")
          .map((word) => word[0])
          .join("")
          .toUpperCase();

        let userRole = record.userRole || "";

        if (record.userRole === "admin") {
          userRole = "admin";
        } else if (
          // record.userRole === "member" &&
          groupData.requireMembershipPackage
        ) {
          let packId = record.package;

          if (
            !packId ||
            !(
              packId &&
              packId?.length > 0 &&
              record.packageSeason &&
              activePackages.find(
                (pkg) => pkg.key === `${record.package}|${record.packageSeason}`
              )
            )
          ) {
            userRole = "inactive";
          }
        }

        return (
          <div className="d-flex">
            <div>
              {record.profileImage ? (
                <Avatar size="large" src={record.profileImage} />
              ) : (
                <Avatar
                  size="large"
                  style={{ backgroundColor: "var(--primary-background)" }}
                >
                  {nameInitials}
                </Avatar>
              )}
            </div>

            <div className="d-flex gap-2">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginLeft: 20, fontWeight: "bold" }}>
                  {name
                    ? name
                    : record.firstName
                      ? `${record.firstName} ${record.lastName}`
                      : ""}
                </span>
                <span style={{ marginLeft: 20, fontSize: 14 }}>
                  {record.title || ""}
                </span>
                <span style={{ marginLeft: 20, fontSize: 14 }}>{userRole}</span>
              </div>
              <div className="member-tags">
                {record.childMembers && (
                  <Tooltip
                    color="var(--primary-background)"
                    className="family-tooltip"
                    style={{ backgroundColor: "white" }}
                    title={() => {
                      return (
                        <div>
                          {record.childMembers.map((item, index) => {
                            return (
                              <p>{`Child ${index + 1}: ${item.name} (${item.membershipNumber})`}</p>
                            );
                          })}
                        </div>
                      );
                    }}
                  >
                    <Tag color="error">Family</Tag>
                  </Tooltip>
                )}
                {/* {
                  <MessageOutlined />
                } */}

                {record && record.notes && (
                  <Tooltip
                    placement="topLeft"
                    title={() => (
                      <div
                        style={{
                          height: "fit-content",
                          maxWidth: "400px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {record.notes}
                      </div>
                    )}
                  >
                    <img
                      src={NoteIcon}
                      alt="member-note"
                      style={{ width: 18, height: 18 }}
                    />
                  </Tooltip>
                )}
                {record && record.lockStatus && <LockOutlined />}
              </div>
            </div>
          </div>
        );
      },
      width: window.screen.width > 500 ? 300 : 150,
    },

    {
      title: "Email",
      width: window.screen.width > 500 ? 300 : 150,
      dataIndex: "email",
      sorter: (a, b) => sortObjectByString(a, b, "email"),
    },
    {
      title: (
        <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
          <span className="flex-center">Phone</span>
          <HelpTooltip
            text="Phone number displayed is what the member shared when buying
                  their membership package. It does not control app
                  authentication. We hide that number, treating it like a
                  Username."
          />
        </div>
      ),
      width: window.screen.width > 500 ? 200 : 150,
      dataIndex: "phone",
    },
    {
      title: "Birthday",
      width: 150,
      dataIndex: "birthday",
      render: (data) => {
        if (data && Object.keys(data).length > 0) {
          let birthday = `${data.month} ${data.date}`;
          if (data.year) {
            birthday = `${birthday} ${data.year}`;
          }
          return <span>{birthday}</span>;
        }
        return "";
      },
    },
    {
      title: "Hometown",
      dataIndex: "hometown",
      key: "hometown",
      width: 150,

      sorter: (a, b) => sortObjectByString(a, b, "hometown"),
    },
    {
      title: "Street",
      key: "streetName",
      width: window.screen.width > 500 ? 200 : 150,
      dataIndex: "address1",

      render: (data) => <span style={{}}>{data}</span>,
    },
    {
      title: "Address 2",
      key: "address2",

      dataIndex: "address2",
      render: (data) => <span style={{}}>{data}</span>,
      width: window.screen.width > 500 ? 200 : 150,
    },
    {
      title: "City",
      key: "city",

      dataIndex: "city",
      render: (data) => <span style={{}}>{data}</span>,
      width: window.screen.width > 500 ? 200 : 150,
    },
    {
      title: "State",
      key: "state",

      dataIndex: "state",
      render: (data) => <span style={{}}>{data}</span>,
      width: window.screen.width > 500 ? 200 : 150,
    },
    {
      title: "Country",
      key: "country",

      dataIndex: "country",
      render: (data) => <span style={{}}>{data}</span>,
      width: window.screen.width > 500 ? 200 : 150,
    },
    {
      title: "Zip",
      key: "zip",

      dataIndex: "zip",
      render: (data) => <span style={{}}>{data}</span>,
      width: window.screen.width > 500 ? 200 : 150,
    },

    {
      title: (
        <div>
          <p style={{ marginBottom: 0 }}>Member</p>
          <p style={{ marginBottom: 0 }}>Since</p>
        </div>
      ),
      dataIndex: "memberSince",

      sorter: (a, b) => sortObjectByString(a, b, "memberSince"),
      width: 100,
    },
    {
      title: "Membership #",
      dataIndex: "membershipNumber",

      sorter: (a, b) => sortObjectByString(a, b, "membershipNumber"),
      width: 150,
    },
    {
      title: (
        <div>
          <p style={{ marginBottom: 0 }}>Official Club</p>
          <p style={{ marginBottom: 0 }}>Member #</p>
        </div>
      ),
      key: "officialClubMember",

      dataIndex: "officialClubMember",
      width: 150,
    },
    {
      title: "Receive Email?",
      key: "joinedEmailList",
      dataIndex: "joinedEmailList",
      render: (data) => <span style={{}}>{data ? "Yes" : "No"}</span>,
      width: 100,
    },
    {
      title: "Volunteer?",
      key: "notifyForVolunteerOpportunities",

      dataIndex: "shareVolunteerOpportunities",
      render: (data) => <span style={{}}>{data ? "Yes" : "No"}</span>,
      width: 100,
    },
    {
      title: "Season Ticket",
      key: "seasonTicketHolder",

      dataIndex: "seasonTicketHolder",
      render: (data) =>
        data &&
        Object.keys(data).length > 0 &&
        `${data.section || data.season || ""}/${data.seats || data.noOfSeats || ""}`,
      width: 100,
    },
    {
      title: (
        <div>
          <p style={{ marginBottom: 0 }}>Registration </p>
          <p style={{ marginBottom: 0 }}>(Group)</p>
        </div>
      ),
      key: "registrationDate",
      dataIndex: "registrationDate",
      width: 150,
      render: (timestamp) => {
        if (timestamp) {
          const isUnix = timestamp.toString().length === 10;
          let date = isUnix ? dayjs.unix(timestamp) : dayjs(timestamp);

          if (date.isValid()) {
            date = date.format("MMM Do, YYYY");
          } else {
            date = "";
          }
          return <span>{date}</span>;
        }
      },
      sorter: (a, b) => sortObjectByNumber(a, b, "registrationDate"),
    },
    {
      title: (
        <div>
          <p style={{ marginBottom: 0 }}>Registration </p>
          <p style={{ marginBottom: 0 }}>(Chant)</p>
        </div>
      ),
      key: "registeredOnChantDate",

      dataIndex: "registeredOnChantDate",
      render: (timestamp) => {
        if (timestamp) {
          const isUnix = timestamp.toString().length === 10;
          let date = isUnix ? dayjs.unix(timestamp) : dayjs(timestamp);

          if (date.isValid()) {
            date = date.format("MMM Do, YYYY");
          } else {
            date = "";
          }
          return <span>{date}</span>;
        }
      },
      width: 150,
      sorter: (a, b) => sortObjectByNumber(a, b, "registeredOnChantDate"),
    },
    {
      title: "Chapter",
      width: 200,

      dataIndex: "chapter",
    },
    {
      title: "Referred By",
      width: 200,
      dataIndex: "referredBy",
    },
  ];

  if (currentPackages && currentPackages?.length > 0) {
    columns.splice(2, 0, {
      title: "Package",
      width: 250,
      key: "package",
      dataIndex: ["packageSeason", "package"],
      render: (_, record) => {
        const getPackageName = () => {
          // if (record.packageSeason) {
          //   const pack = currentPackages?.find((p) => {
          //     return (
          //       p.packageTimeline &&
          //       record.packageSeason in p.packageTimeline &&
          //       p.id === record.package
          //     );
          //   });
          //   return pack?.packageTimeline[record.packageSeason]?.name || "";
          // }
          // if (record.package) {
          //   const pack = currentPackages?.find((p) => p.id === record.package);
          //   return Array.isArray(pack)
          //     ? pack.name.join(", ")
          //     : pack?.name || "";
          // }
          // return "";

          let packageName = "";
          let packageObj = null;

          let packageSeason = null;

          if (record?.packageSeason) {
            packageSeason = record?.packageSeason || null;
          } else {
            const pkgDetails = currentPackages?.find(
              (pkg) => pkg?.id === record?.package
            );

            if (pkgDetails) {
              packageSeason = getMinPackageSeasonFromTimeline(
                pkgDetails?.packageTimeline
              );
            }
          }

          packageObj = record?.package
            ? packagesBySeason.find(
                (pack) => pack?.key === `${record?.package}|${packageSeason}`
              )
            : null;

          if (packageObj) {
            packageName = packageObj?.value || "";
          }

          return packageName;
        };

        return (
          <div className="d-flex">
            {record && record?.subscriptionDetail?.status === "active" && (
              <Tooltip
                placement="topLeft"
                title={() => (
                  <div
                    style={{
                      height: "fit-content",
                      maxWidth: "400px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    Auto-Renewal
                  </div>
                )}
              >
                <div className="flex-center">
                  <img
                    src={AutoRenewIcon}
                    alt="auto-renew-note"
                    style={{ width: 18, height: 18, marginRight: 6 }}
                  />
                </div>
              </Tooltip>
            )}
            <span>{getPackageName()}</span>
          </div>
        );
      },
    });
  }

  Object.keys(formLayout?.[groupData?.id]?.customFieldDetails || {}).forEach(
    (key) => {
      let fieldTitle =
        formLayout?.[groupData?.id]?.customFieldDetails[key].fieldTitle;
      let dataIndex = key;
      let col = {
        title: fieldTitle,
        key: key,
        dataIndex,
        width: 200,
      };

      columns.push(col);
    }
  );

  return {
    membersList,
    filteredTableData,
    handleSearchInputChange,
    handleFilterChange,
    currentPackages,
    formLayout,
    groupData,
    handleDropdownChange,
    filters,
    membersLoading,
    editableRecord,
    editableRecordId,
    handleModalOpen,
    saveDetail,
    linkedMember,
    idToAccept,
    showAddMemberModal,
    addMember,
    setShowAddMemberModal,
    registrationData,
    columns,
    verifyAddMemberLoading,
    addMemberLoading,
  };
};

export default useMembers;
